<template>
  <div class="row mx-0 h-100 relative">
    <div class="col-12 p-3 p-md-4 card_item h-100" @click="goTo" :class="{disabled: isLoading, disabled: isDisabled}">
      <div class="row mx-md-0 h-100 justify-content-center">
        <div class="col-12 p-0">
          <div class="row m-0 justify-content-center holder" >
            <div class="col-12 p-0 above">
              <div class="row m-0 justify-content-center">
                <div class="col-auto text-center p-0 mb-3 d-none d-md-block">
                  <IconRound size="large" align="center" bg="var(--green-color)">
                    <slot />
                  </IconRound>
                </div>
                <div class="col-auto text-center p-0 mb-2 d-md-none">
                  <IconRound size="small" align="center"  bg="var(--green-color)">
                    <slot />
                  </IconRound>
                </div>
                <div class="col-12 text-center p-0">
                  <div class="card_heading">
                    <slot name="heading" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10 text-center p-0 d-none d-md-block mt-3">
          <Button color="grey_gold" btnText="View" icon="arrow" class="w-100" v-if="isView">
            <IconArrowForward />
          </Button>
          <Button color="grey_gold" btnText="Manage" icon="arrow" class="w-100" v-else-if="isManage">
            <IconArrowForward />
          </Button>
          <Button color="grey_gold" btnText="View Now" icon="arrow" class="w-100" v-else-if="hasEntries">
            <IconArrowForward />
          </Button>
          <Button color="grey_gold" btnText="View Now" icon="arrow" class="w-100" v-else-if="isDisabled" disabled>
            <IconArrowForward />
          </Button>
          <Button color="green" btnText="Setup Now" icon="arrow" class="w-100" v-else>
            <IconArrowForward />
          </Button>
        </div>
      </div>
    </div>
    <div class="absolute_overlay" v-if="isLoading">
      <div class="row h-100 align-items-center justify-content-center">
        <div class="spinner-border gold-text" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    IconRound: defineAsyncComponent(() => import('../components/IconRound.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue'))
  },
  name: 'ProfileNavCard',
  props: ['icon', 'heading', 'hasEntries', 'isLoading', 'isManage', 'isView', 'isDisabled'],
  methods: {
    goTo () {
      this.$emit('goTo')
    }
  }
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  /* box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px; */
  color: var(--normal-color);
  cursor: pointer;
}
.card_item.disabled {
  opacity: 0.3;
}
.card_heading {
  font-family: "quicksand_bold", Sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--green-color-dark);
}
.holder {
  position: relative;
}
.above {
  z-index: 1;
}
.absolute_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 13px;
}
@media (min-width: 992px) {
  .card_heading {
    font-size: 1.1rem;
  }
}
</style>
